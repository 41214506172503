<template>
  <b-card>
    <div class="d-flex justify-between mb-2">
      <b-container>
        <b-row>
          <b-col>
            <h3 class="font-bold">
              Data Order
            </h3>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-button
                v-if="tabIndex === 1"
                variant="primary"
                size="md"
                class="mr-1"
                to="ajukan-pickup"
              >
                Ajukan Pickup
              </b-button>
              <b-button
                id="btn-export"
                variant="outline-primary"
                size="md"
                right
                @click="openModal()"
              >
                Download
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-row>
      <b-modal
        id="modalExport"
        ref="modalExport"
        centered
        :size="'lg'"
        no-close-on-backdrop
        :header-bg-variant="'white'"
        :header-class="'no-border'"
        ok-title="Download"
        ok-variant="primary"
        cancel-title="Batalkan"
        cancel-variant="outline-primary"
        :footer-class="'no-border'"
        :content-class="'no-border'"
        hide-header
      >
        <template>
          <b-container
            class="text-center col"
            style="color: #222222;"
          >
            <b-row class="justify-content-end">
              <b-icon-x-circle
                style="width: 1.5rem; height: 1.5rem; cursor: pointer; "
                class="close-button-on-popup-pickup text-right"
                end
                @click="closeModalExport"
              />
            </b-row>
            <b-row class="mt-2 justify-content-center">
              <h3
                class="font-weight-bold"
                style="font-size: 24px;"
              >
                Download Data Order
              </h3>
            </b-row>
            <b-row
              class="justify-content-center"
              style="font-size: 18px; font-weight: 500;"
            >
              Kustomisasi data order yang ingin kamu download
            </b-row>
            <b-row class="mt-3">
              <b-col
                sm="12"
                md="3"
              >
                <b-row class="border-b-2 pb-1 font-weight-bold">
                  Tanggal
                </b-row>
                <b-row class="my-1">
                  <b-form-radio
                    v-model="selectedDate"
                    class="mb-1"
                    value="created"
                  >
                    Order Dibuat
                  </b-form-radio>
                  <b-form-radio
                    v-model="selectedDate"
                    value="updated"
                  >
                    Update Status
                  </b-form-radio>
                </b-row>
                <b-row class="my-1 mr-0 mr-md-2">
                  <date-range-picker
                    ref="picker"
                    v-model="rangeDate"
                    :opens="'rtl'"
                    :locale-data="locale"
                    :ranges="ranges"
                  >
                    <template
                      v-slot:input="picker"
                    >
                      <div
                        class="d-flex h-auto justify-content-between align-items-center"
                      >
                        <span
                          class="mr-2"
                        >{{ formatDate(picker.startDate) }} -
                          {{ formatDate(picker.endDate) }}</span>
                        <img src="https://storage.googleapis.com/komerce/assets/icons/date-picker-icon.svg">
                      </div>
                    </template>
                  </date-range-picker>
                </b-row>
              </b-col>
              <b-col
                sm="12"
                md="3"
              >
                <b-row class="border-b-2 pb-1  mt-2 mt-md-0 font-weight-bold">
                  Status
                </b-row>
                <b-row>
                  <div
                    v-if="selectedDate === 'created'"
                    class="my-1"
                  >
                    <b-form-checkbox
                      v-for="(item, idx) in listOrderStatus"
                      :id="item.name"
                      :key="idx"
                      v-model="orderStatus"
                      class="text-left"
                      :class="idx === 0 ? '' : 'my-1'"
                      :value="item.name"
                    >
                      {{ item.name }}
                    </b-form-checkbox>
                  </div>
                  <div
                    v-else
                    class="my-1"
                  >
                    <b-form-radio
                      v-for="(item, idx) in listOrderStatus"
                      :id="item.name"
                      :key="idx"
                      v-model="orderStatus"
                      class="text-left"
                      :class="idx === 0 ? '' : 'my-1'"
                      :value="item.name"
                    >
                      {{ item.name }}
                    </b-form-radio>
                  </div>
                </b-row>
              </b-col>
              <b-col
                sm="12"
                md="3"
              >
                <b-row class="border-b-2 pb-1 mt-2 mt-md-0 font-weight-bold">
                  Metode Pembayaran
                </b-row>
                <b-row>
                  <div>
                    <div class="my-1">
                      <b-form-checkbox
                        id="chcekedCod"
                        v-model="paymentMethod"
                        name="chcekedCod"
                        value="COD"
                        class="text-left"
                      >
                        COD
                      </b-form-checkbox>
                    </div>
                    <div class="my-1">
                      <b-form-checkbox
                        id="chcekedBankTransfer"
                        v-model="paymentMethod"
                        name="chcekedBankTransfer"
                        class="text-left"
                        value="BANK TRANSFER"
                      >
                        Bank Transfer
                      </b-form-checkbox>
                    </div>
                  </div>
                </b-row>
              </b-col>
              <b-col
                sm="12"
                md="3"
              >
                <b-row class="border-b-2 pb-1  mt-2 mt-md-0 font-weight-bold">
                  Ekspedisi
                </b-row>
                <b-row>
                  <div class="my-1">
                    <b-form-checkbox
                      v-for="(item, idx) in expedition"
                      :id="item.shipping_name"
                      :key="item.id"
                      v-model="shipping"
                      class="text-left"
                      :class="idx === 0 ? '' : 'my-1'"
                      :value="item.shipping_name"
                    >
                      {{ item.shipping_name }}
                    </b-form-checkbox>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </template>
        <template #modal-footer>
          <b-col>
            <div
              v-if="showProgress"
              class="d-flex flex-column"
            >
              <div>{{ Math.floor(progressValue) > 100 ? 'Download selesai' : message }}</div>
              <div class="row w-100 align-items-center">
                <div class="col">
                  <b-progress
                    v-if="Math.floor(progressValue) <= 0 "
                    :value="100"
                    :max="100"
                    variant="success"
                    :striped="Math.floor(progressValue) <= 0 ? true : false"
                    :animated="Math.floor(progressValue) <= 0 ? true : false"
                  />
                  <b-progress
                    v-else
                    :value="progressValue"
                    :max="100"
                    variant="success"
                  />
                </div>
                <div class="col-auto">
                  {{ Math.floor(progressValue) > 100 ? 100 :0 }}%
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex flex-row justify-content-end">
              <button
                class="btn btn-outline-primary m-1"
                @click="closeModalExport"
              >
                Batalkan
              </button>
              <button
                :disabled="showProgress"
                class="btn btn-primary m-1"
                @click="downloadCsv"
              >
                Download
                <span class="ml-1">
                  <b-spinner
                    v-if="loading"
                    small
                    label="Loading..."
                  />
                </span>
              </button>
            </div>
          </b-col>

        </template>
      </b-modal>
    </b-row>
    <b-tabs
      v-model="tabIndex"
      fill
      :nav-class="'mb-1 font-bold text-xl'"
      no-nav-style
    >
      <b-tab
        title="Semua"
        :title-link-class="linkClass(0)"
        lazy
      >
        <all :filter-item="filterProductWarehouse" />
      </b-tab>
      <b-tab
        :title-link-class="linkClass(1)"
        lazy
      >
        <template slot="title">
          <span>{{ totalAjukan }} | Order Dibuat</span>
        </template>
        <created :filter-item="filterProductWarehouse" />
      </b-tab>
      <b-tab
        :title-link-class="linkClass(2)"
        lazy
      >
        <template slot="title">
          {{ totalPacking }} | Dipacking
        </template>
        <packing :filter-item="filterProductWarehouse" />
      </b-tab>
      <b-tab
        :title-link-class="linkClass(3)"
        lazy
      >
        <template slot="title">
          <div
            class="d-flex justify-center"
            @click="tabIndex === 3 ? $router.go() : null"
          >
            {{ totalKirim }} | Dikirim
            <div
              v-if="totalProblem > 0"
              class="absolute my-auto bg-white rounded-lg"
              style="padding: 3px;margin-top: -18px!important;margin-left: 15%;"
            >
              <b-badge
                variant="danger"
                class="text-sm rounded-lg"
                style="padding: 2px 6px!important;font-size: 12px;"
              >
                <span class="d-flex text-sm">
                  {{ totalProblem }}
                  <img
                    class="w-4"
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/danger.svg"
                    style="margin-left:3px"
                  >
                </span>
              </b-badge>
            </div>
          </div>
        </template>
        <send :filter-item="filterProductWarehouse" />
      </b-tab>
      <b-tab
        title="Diterima"
        :title-link-class="linkClass(4)"
        lazy
      >
        <received :filter-item="filterProductWarehouse" />
      </b-tab>
      <b-tab
        title="Retur"
        :title-link-class="linkClass(5)"
        lazy
      >
        <retur :filter-item="filterProductWarehouse" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import {
  BCard, BSpinner, BProgressBar, BTabs, BTab, BButton, BBadge, BCol, BRow, BContainer, BIconXCircle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { mapState } from 'vuex'
import axios from 'axios'
import {
  firstDateOfMonth,
  formatYmd,
  last30,
  last7,
  today,
} from '@/store/helpers'
import secureLs from '@/libs/secureLs'
import All from './List/All.vue'
import Created from './List/Created.vue'
import Packing from './List/Packing.vue'
import Send from './List/Send.vue'
import Received from './List/Received.vue'
import Retur from './List/Retur.vue'

export default {
  components: {
    BCard, BTabs, BTab, All, Created, Packing, Send, Received, Retur, BButton, BCol, BContainer, BRow, BIconXCircle, DateRangePicker,
  },
  filters: {
    dateCell(value) {
      const dt = new Date(value)

      return dt.getDate()
    },
    date(val) {
      return val ? val.toLocaleString() : ''
    },
  },
  data() {
    const tabs = ['semua', 'order-dibuat', 'dipacking', 'dikirim', 'diterima', 'retur']
    return {
      tabIndex: tabs.indexOf(this.$route.query.tab),
      tabs,
      date: {
        end: this.$moment(today).startOf('day').format('YYYY-MM-DD'),
        start: this.$moment(today).startOf('day').format('YYYY-MM-DD'),
      },
      formatYmd,
      last7,
      last30,
      today,
      profile: secureLs.getItem('userData'),
      totalAjukan: null,
      totalPacking: null,
      showProgress: false,
      message: 'Sedang memuat file ...',
      progressValue: 0,
      totalKirim: null,
      controller: new AbortController(),
      totalProblem: null,
      orderDate: '',
      loading: false,
      paymentMethod: ['COD', 'BANK TRANSFER'],
      orderStatus: [],
      shipping: [],
      locale: {
        applyLabel: 'Pilih',
        cancelLabel: 'Batal',
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      },
      ranges: {
        'Hari Ini': [today, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
      },
      rangeDate: {
        startDate: firstDateOfMonth,
        endDate: today,
      },
      filterProductWarehouse: {},
      selectedDate: 'created',
      listOrderStatus: [
        {
          name: 'Diajukan',
        },
        {
          name: 'Dipacking',
        },
        {
          name: 'Dikirim',
        },
        {
          name: 'Diterima',
        },
        {
          name: 'Retur',
        },
        {
          name: 'Hilang',
        },
        {
          name: 'Rusak',
        },
      ],
    }
  },
  watch: {
    tabIndex(newValue) {
      const tab = this.tabs[newValue]
      this.$router.replace({ query: { tab } }).catch(() => {})
    },
    selectedDate(newVal) {
      this.orderStatus = newVal === 'created' ? this.listOrderStatus.map(item => item.name) : 'Retur'
    },
  },
  computed: {
    ...mapState('filter', ['expedition']),
  },
  async created() {
    await this.fetchData()
    await this.$http_komship.get('/v1/list/filter-product')
      .then(async response => {
        this.filterProductWarehouse = await response.data.data
      }).catch(err => {
        this.filterProductWarehouse = {
          warehouses: [],
          products: [],
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    linkClass(tabs) {
      if (this.tabIndex === tabs) {
        return ['bg-primary', 'text-white', 'rounded']
      }
      return ['bg-default', 'text-dark']
    },
    formatDate(d) {
      return moment(d).format('D MMM YYYY')
    },
    fetchData() {
      this.$http_komship.get(`v1/order/count/order-problem/${this.profile.partner_detail.id}`)
        .then(res => {
          const { data } = res.data
          this.totalAjukan = data.diajukan
          this.totalPacking = data.dipacking
          this.totalKirim = data.dikirim
          this.totalProblem = data.order_problem
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          })
        })
    },
    downloadCsv() {
      const params = {
        start_date: formatYmd(this.rangeDate.startDate),
        end_date: formatYmd(this.rangeDate.endDate),
        payment_method: this.paymentMethod.toString(),
        order_status: this.orderStatus.toString(),
        shipping: this.shipping.toString(),
        last_update: this.selectedDate === 'updated' ? 1 : 0,
      }
      this.loading = true
      this.showProgress = true
      this.progressValue = 0
      axios.get(`${process.env.VUE_APP_BASE_URL_NEW}/komship/api/v1/orders/export`, {
        params,
        onDownloadProgress: progressEvent => {
          this.message = 'Sedang mendownload file ...'
          // eslint-disable-next-line prefer-const
          let total = progressEvent?.srcElement?.getResponseHeader('content-length') || 0
          this.progressValue = (progressEvent.loaded / total) * 100
        },
        responseType: 'arraybuffer',
        signal: this.controller.signal,
      }).then(result => {
        setTimeout(() => {
          const file = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const fileURL = URL.createObjectURL(file)
          const link = document.createElement('a')
          link.href = fileURL
          const fileName = `Data-Order-${formatYmd(this.rangeDate.startDate)}-${formatYmd(this.rangeDate.endDate)}.xlsx`// whatever your file name .
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
          this.loading = false
          this.showProgress = false
          this.message = 'Sedang memuat file ...'
        }, 1000)
      }).catch(err => {
        this.loading = false
      })
    },
    closeModalExport() {
      this.message = 'Sedang memuat file ...'
      this.controller.abort()
      this.showProgress = false
      this.selectedDate = 'created'
      this.$refs.modalExport.hide()
    },
    openModal() {
      if (this.expedition.length === 0) {
        this.$store.dispatch('filter/getUpdateExpedition')
      }
      this.shipping = this.expedition.map(item => item.shipping_name)
      this.orderStatus = this.listOrderStatus.map(item => item.name)
      this.$bvModal.show('modalExport')
    },
  },
}
</script>
<style lang="scss" scoped>
.slot {
  background-color: #aaa;
  padding: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-black {
  color: #000;
}

.daterangepicker td.active,.daterangepicker td.active:hover{
    background-color: #FF6A3A !important;
    color: white !important;
  }

  .daterangepicker td.in-range {
    background-color: #FFECE9 !important;
    border-color: transparent;
    color: #000;
    border-radius: 0;
}
 .daterangepicker td.active{
    border-color: transparent;
    color: #000;
    border-radius: 0;
    background-color: #FF6A3A !important;
}
.daterangepicker .ranges li.active{
    background-color: #FF6A3A !important;
}
.daterangepicker .btn-primary, .daterangepicker .btn-success {
    background-color: #FF6A3A !important;
    border-color: #FF6A3A !important;
}
    @media screen and (max-width: 767px) {
        .daterangepicker.show-calendar .drp-buttons, .daterangepicker.show-calendar .drp-calendar {
            display: flex !important;
        }
        .calendars {
            display: flex;
            width: 100% !important;
        }
    }
    .form-control, .reportrange-text{
        height: auto !important;
      }

.calendars{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
    width: 100% !important;
}

</style>
